<template>
    <div>
        <!-- <v-btn
                class='ma-5 ml-5 mr-1'
                color="primary"
                @click="print"
            >PRINT</v-btn> -->
        <v-btn
            v-if="!track_data.printing"
            class='pdf ma-5 ml-5 mr-1'
            color="primary"
            @click="pdf"
            :loading="generating"
            :disabled="generating"
        >
            <v-icon left>mdi-download</v-icon> PDF
        </v-btn>
        <!-- -----------------------------------------------------------------INVOICE -->
        <div
            v-if="mode == 'invoice'"
            :class='[track_data.printing ? "printing":"","invoice"]'
            id='invoice'
        >
            <div class='back'></div>
            <h1>{{invoice_data.custom.before_string}}Facture{{invoice_data.custom.after_string}}</h1>
            <div
                class='logo'
                :style="{
                    'background-image':`url(${invoice_data.custom.logo})`,
                    'transform':`scale(${invoice_data.custom.logo_scale})`
                }"
            ></div>
            <div class='infos number'>
                <table
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr class='bold'>
                        <td>Numéro de facture</td>
                        <td>&emsp;{{invoice_data.number}}</td>
                    </tr>
                    <tr>
                        <td>Date d'émission</td>
                        <td>&emsp;{{invoice_data.emit_day}}</td>
                    </tr>
                    <tr>
                        <td>Date d'échéance</td>
                        <td>&emsp;{{invoice_data.payday}}</td>
                    </tr>
                    <tr
                        v-for="(line,index) in more_lines"
                        :key="index"
                    >
                        <td>{{line[0]}}</td>
                        <td>&emsp;{{line[1]}}</td>
                    </tr>
                </table>
            </div>
            <!-- <b>Numéro de facture&emsp;{{invoice_data.number}}</b><br />
                Date d'émission&emsp;&emsp;{{invoice_data.emit_day}}<br />
                Date d'échéance&emsp;&emsp;{{invoice_data.payday}} -->
            <v-row class='parties mt-0'>
                <v-col class='company'>
                    <b>{{invoice_data.company.name}}</b><br />
                    <template v-if="invoice_data.company.siren"><b>{{invoice_data.company.siren}}</b><br /></template>
                    {{invoice_data.company.street}}<br />
                    {{invoice_data.company.city}}<br />
                    {{invoice_data.company.country}}<br />
                    {{invoice_data.company.telephone}}<br />
                    {{invoice_data.company.email}}<br />
                </v-col>
                <v-col class='client'>
                    <b>Facturé à</b><br />
                    {{invoice_data.client.name}}<br />
                    <template v-if="invoice_data.client.siren">{{invoice_data.client.siren}}<br /></template>
                    {{invoice_data.client.street}}<br />
                    {{invoice_data.client.city}}<br />
                    {{invoice_data.client.country}}<br />
                    {{invoice_data.client.telephone}}<br />
                    {{invoice_data.client.email}}<br />
                </v-col>
            </v-row>
            <h2 class='amount'>{{due_text}}</h2>

            <div class='products'>
                <table
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr>
                        <th class='desc'>Description</th>
                        <th>Qté</th>
                        <th>Prix Unitaire</th>
                        <th class='end_price'>Montant</th>
                    </tr>
                    <tr
                        v-for="(product,index) in invoice_data.products"
                        :key="index"
                        :class='["product",product.description ? "with_desc":""]'
                        :style="{
                            height: product_height+'px',
                            'line-height': 1.5 * (product_height / 60)
                        }"
                    >
                        <td class='line_top'>
                            {{product.name}}
                            <div
                                class='fade mt-0 description'
                                v-if="product.description && product_height>=40"
                            >{{product.description}}</div>
                        </td>
                        <td class='line_top'>
                            {{product.quantity}}
                            <v-icon v-if="product.pay_type=='recurring'">mdi-sync</v-icon>
                        </td>
                        <td class='line_top'>{{period_price(product)}}</td>
                        <td class='end_price line_top'>{{price_display(product.price*product.quantity)}}</td>
                    </tr>
                    <tr
                        v-for="(name) in tot_disp"
                        :key="name"
                        style='height:40px'
                        :class="name == 'Montant dû' ? 'bold':''"
                    >
                        <td></td>
                        <td class='line_top'>{{name}}</td>
                        <td class='line_top'></td>
                        <td class='end_price line_top'>{{price_to_display(name)}}</td>
                    </tr>
                </table>
                <div class='infos'>
                    TVA non applicable art. 293 b du cgi
                </div>

                <div
                    class='infos payment_infos'
                    v-if="payment_elms"
                >
                    <table
                        cellspacing="0"
                        cellpadding="0"
                    >
                        <tr
                            v-for="([pelmname,pelmdata], index) in payment_elms"
                            :key="pelmname"
                            :class="index == 0 ? 'bold' : ''"
                        >
                            <td>{{pelmname}}</td>
                            <td>&emsp;{{pelmdata}}</td>
                        </tr>
                    </table>
                </div>

                <div class='footer'>
                    {{invoice_data.number}} · {{due_text}}
                </div>
            </div>
        </div>
        <!-- -----------------------------------------------------------------ESTIMATE -->
        <div
            v-if="mode == 'estimate'"
            :class='[track_data.printing ? "printing":"","estimate"]'
            id='invoice'
        >
            <v-row class='ma-0'>
                <v-col class='important mt-0'>
                    <h2>{{invoice_data.custom.before_string}}DEVIS{{invoice_data.custom.after_string}}</h2>
                </v-col>
                <v-col
                    style="text-align:right"
                    class='mt-0 amount'
                >
                    Valide jusqu'au {{invoice_data.expire_day}}
                    <h2 class='important'>{{price_display(full_amount)}}</h2>
                </v-col>
            </v-row>
            <div
                class='logo important'
                :style="{
                    'background-image':`url(${invoice_data.custom.logo})`,
                    'transform':`scale(${invoice_data.custom.logo_scale})`
                }"
            ></div>

            <hr />
            <div class='infos number'>
                <table
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr class='bold'>
                        <td>NUMÉRO DE DEVIS</td>
                        <td class='important right'>&emsp;{{invoice_data.number}}</td>
                    </tr>
                    <tr>
                        <td>DATE D'ÉMISSION</td>
                        <td class='important right'>&emsp;{{invoice_data.emit_day}}</td>
                    </tr>
                    <tr>
                        <td>DATE D'EXPIRATION</td>
                        <td class='important right'>&emsp;{{invoice_data.expire_day}}</td>
                    </tr>
                </table>
            </div>

            <v-row class='parties mt-0'>
                <v-col class='company'>
                    <b class='important'>{{invoice_data.company.name}}</b><br />
                    <template v-if="invoice_data.company.siren"><b>{{invoice_data.company.siren}}</b><br /></template>
                    {{invoice_data.company.street}}<br />
                    {{invoice_data.company.city}}<br />
                    {{invoice_data.company.country}}<br />
                    {{invoice_data.company.telephone}}<br />
                    {{invoice_data.company.email}}<br />
                </v-col>
                <v-col class='client'>
                    DEVIS POUR<br />
                    <b>{{invoice_data.client.name}}</b><br />
                    <template v-if="invoice_data.client.siren"><b>{{invoice_data.client.siren}}</b><br /></template>
                    <b>{{invoice_data.client.street}}</b><br />
                    <b>{{invoice_data.client.city}}</b><br />
                    <b>{{invoice_data.client.country}}</b><br />
                    <b>{{invoice_data.client.telephone}}</b><br />
                    <b>{{invoice_data.client.email}}</b><br />
                </v-col>
            </v-row>

            <div
                class='products'
                style='margin-top:35px'
            >
                <table
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr>
                        <th class='desc'>DESCRIPTION</th>
                        <th>QTÉ</th>
                        <th>PRIX UNITAIRE</th>
                        <th class='end_price'>MONTANT</th>
                    </tr>
                    <tr
                        v-for="(product,index) in invoice_data.products"
                        :key="index"
                        :class='["product",product.description ? "with_desc":""]'
                        :style="{
                            height: product_height+'px',
                            'line-height': 1.5 * (product_height / 60)
                        }"
                    >
                        <td class='line_top'>
                            {{product.name}}
                            <div
                                class='fade mt-0 description'
                                v-if="product.description && product_height>=40"
                            >{{product.description}}</div>
                        </td>
                        <td class='line_top'>
                            {{product.quantity}}
                            <v-icon v-if="product.pay_type=='recurring'">mdi-sync</v-icon>
                        </td>
                        <td class='line_top'>{{period_price(product)}}</td>
                        <td class='end_price line_top'>{{price_display(product.price*product.quantity)}}</td>
                    </tr>
                    <tr class='liner'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr
                        v-for="(name) in tot_disp"
                        :key="name"
                        style='height:40px'
                        :class="name == 'Total' ? 'important':''"
                    >
                        <td></td>
                        <td class='line_top'>{{name}}</td>
                        <td class='line_top'></td>
                        <td class='end_price line_top'>{{price_to_display(name)}}</td>
                    </tr>
                </table>
            </div>
            <div
                class='infos payment_infos full_width payment_conditions'
                v-if="payment_cond_elms"
            >

                <table
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr
                        v-for="([pelmname,pelmdata], index) in payment_cond_elms"
                        :key="pelmname"
                        :class="index == 0 ? 'bold' : ''"
                    >
                        <td>{{pelmname}}</td>
                        <td>&emsp;{{pelmdata}}</td>
                    </tr>
                </table>
                <v-row
                    class='sign'
                    style='margin-top:20px !important; padding-bottom:70px !important'
                >
                    <v-col class='left'>
                        <b>Signature du Prestataire</b><br />
                        <v-divider class='mr-10'></v-divider>
                        <span class='infos'>
                            Datée et précédée de la mention "Bon pour accord"
                        </span>
                    </v-col>
                    <v-col class='right'>
                        <b>Signature du Client</b><br />
                        <v-divider class='ml-10'></v-divider>
                        <span class='infos'>
                            Datée et précédée de la mention "Bon pour accord"
                        </span>
                    </v-col>
                </v-row>
            </div>
            <div class='footer'>
                Vous avez des questions ? Contactez {{invoice_data.company.name}} à l'adresse {{invoice_data.company.email}} ou appelez le {{invoice_data.company.telephone}}
            </div>
        </div>
    </div>
</template>

<script>
import App from '../App.vue'
export default {
    components: { App },
    props: ['invoice_data', 'track_data'],
    data: () => ({
        generating: false,
    }),
    computed: {
        mode() {
            return this.invoice_data.mode
        },
        tot_disp() {
            return {
                'invoice': ['Sous-total', 'Remise', 'Total', 'Montant dû'].filter(n => n != 'Remise' || this.discount != 0),
                'estimate': ['Sous-total', 'Total']
            }[this.mode]
        },
        due_text() {
            const price = this.full_amount
            const price_str = this.price_display(price)
            const paied = this.invoice_data.paied
            return paied ?
                `${price_str} payé${price == 1 ? '' : 's'} le ${this.invoice_data.payday}`
                :
                `${price_str} du${price == 1 ? '' : 's'} le ${this.invoice_data.payday}`
        },
        filename() {
            return this.mode + '-' + this.invoice_data.number
        },
        sub_total() {
            return this.invoice_data.products.map(p => p.price * p.quantity).reduce((a, b) => a + b, 0)
        },
        product_height() {
            const product_count = this.invoice_data.products.length
            const max_product_height = 60
            const min_product_height = 10
            const available_place = this.mode == 'invoice' ?
                380 - ((this.tot_disp.length + this.more_lines.length + this.payment_elms.length) * 30)
                :
                340 - (this.payment_cond_elms.length * 30)
            return Math.min(max_product_height, Math.max(min_product_height, available_place / product_count))
        },
        discount() {
            const giver = this.invoice_data.give_back
            if (!giver || giver == '0') return 0
            if (giver.includes('%')) {
                const amount = this.sub_total
                const discount_percent = parseFloat(giver.replace('%', ''))
                return amount * (discount_percent / 100)
            }
            return parseFloat(giver)
        },
        full_amount() {
            return this.sub_total - this.discount
        },
        payment_elms() {
            const payment_method = this.invoice_data.payment_method[0]
            if (!payment_method) return []
            return [['Mode de paiement', payment_method.mode], ...payment_method.infos.split('\n').map(d => d.split(': '))]
        },
        payment_cond_elms() {
            const payment_conditions = this.invoice_data.payment_conditions ?? ''
            const payment_names = ['à la commande', 'en cours de prestation', 'en fin de prestation']
            const all_payments_infos = [payment_conditions.before, payment_conditions.during, payment_conditions.after]
            const all_payments_computed = all_payments_infos.map(from_price => {
                let price = from_price
                let after = ''
                if (price.includes(':')) {
                    [from_price, after] = price.split(':')
                    price = from_price
                }
                if (!price) return null
                if (price.includes('%')) {
                    price = this.full_amount * (parseFloat(price.replace('%', '')) / 100)
                }
                return { from: from_price, to: this.price_display(price), after }
            })
            const all_payments_str = all_payments_computed.map((stage, i) => {
                if (!stage) return null
                return stage.from.includes('%') ?
                    `${stage.from} ${payment_names[i]} soit ${stage.to}${stage.after}` :
                    `${stage.to} ${payment_names[i]}${stage.after}`
            }).filter(e => e)
            const default_str = all_payments_str.length == 0 ?
                [
                    [`Versement d'un acompte de ${this.price_display(this.full_amount)}`, ''],
                ]
                :
                []
            return [
                ['Conditions de règlement', ''],
                ...default_str,
                ...all_payments_str.map(e => [e, '']),
                [`Paiement par ${this.invoice_data.client.name}`, '']
            ]
        },
        more_lines() {
            const more_lines = this.invoice_data.custom.more_lines
            return more_lines.split('\n').map(l => l.split(': '))
        }
    },
    methods: {
        price_to_display(name) {
            return {
                'Sous-total': this.price_display(this.sub_total),
                'Total': this.price_display(this.full_amount),
                'Remise': '- ' + this.price_display(this.invoice_data.give_back),
                'Montant dû': this.price_display(this.full_amount),
            }[name]
        },
        price_display(price) {
            let used_currency = this.invoice_data.currency
            if (String(price).includes('%')) {
                price = parseFloat(price.replace('%', ''))
                used_currency = '%'
            }
            const full_part = String(parseInt(price * 10 * 10))
            const sub_part = full_part.slice(full_part.length - 2, full_part.length)
            const int_part = full_part.slice(0, full_part.length - 2)
            const int_part_final = Array.from(int_part ? int_part : '0').reverse()
                .reduce((a, b, i) => i != 0 && (i) % 3 == 0 ? a.concat(b + ' ') : a.concat(b), []).reverse().join('')
            return int_part_final + ',' + sub_part + ' ' + used_currency
        },
        period_price(product) {
            const price = this.price_display(product.price)
            if (product.pay_type == 'one-time') return price
            const period_disp = {
                'day': 'jour',
                'week': 'semaine',
                'month': 'mois',
                'year': 'année',
            }[product.period]
            return price + '/' + period_disp
        },
        print() {
            this.track_data.printing = true
            setTimeout(() => {
                window.print()
                this.track_data.printing = false
            })
        },
        async pdf() {
            this.generating = true
            const resp = await fetch('/api/generate_pdf', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.invoice_data)
            })
            const pdf_url = await resp.json()
            const pdf_name = pdf_url.split('/').pop()
            const resp2 = await fetch(pdf_url)
            const buffer = await resp2.arrayBuffer()
            const blob = new Blob([buffer], { type: "application/pdf" })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            a.href = url
            a.download = pdf_name
            a.click()
            window.URL.revokeObjectURL(url)
            this.generating = false
        }
    },
}
</script>

<style scoped>
.invoice,
.estimate {
    -webkit-print-color-adjust: exact !important;
    position: relative;
    background: var(--v-background-base);
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.3);
    width: 805px;
    height: 1127px;
    transform-origin: top center;
    transform: scale(0.7);
    overflow: hidden;
    padding: 60px;
    font-size: 13px;
    font: "Roboto";
    font-weight: normal;
    letter-spacing: 0.2px;
}
/* .back {
    position: absolute;
    width: 1000px;
    height: 2000px;
    background: var(--v-primary-base);
    z-index: -1;
    opacity: 0.02;
    top: 0px;
    left: 0px;
    transform: rotate(45deg);
} */
.bold {
    font-weight: bold;
}
.printing {
    box-shadow: none;
    transform: scale(1);
}
.fade {
    opacity: 0.7;
}
h1 {
    font-size: 40px;
}
h2 {
    font-size: 25px;
}
.invoice::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 10px;
    background: #000;
    top: 0px;
    left: 0px;
}
.estimate::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 10px solid #f1f1f1;
    top: 0px;
    left: 0px;
}
.infos div,
.infos td {
    margin: 0px !important;
    padding: 0px !important;
}
.infos table {
    width: auto;
}
.clear {
    clear: both;
    margin: 100px;
}
.description {
    font-size: 12px;
}
.right {
    text-align: right;
}
.invoice div,
.estimate div {
    margin-top: 30px;
    color: #777;
}
.invoice div {
    color: #000 !important;
}
.important {
    color: #000 !important;
}
.logo {
    position: absolute;
    background: url("/company_logo.png");
    height: 70px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    transform-origin: right center;
    top: 50px;
    right: 60px;
    margin: 0px !important;
}
.estimate .logo {
    top: 105px;
    right: 0px;
    background-position: center center;
    transform-origin: center center;
    /* filter: grayscale(0) contrast(50%) brightness(150%); */
}
/* .invoice .company {
    float: left;
}
.invoice .client {
    float: right;
} */
/* .estimate .parties {
    float: left;
}
.estimate .infos {
    float: right;
    margin-top: 60px;
} */
.estimate .number {
    margin-top: 50px;
}
.amount {
    margin: 60px 0px 40px 0px;
}
.estimate .amount {
    margin: 0px 0px;
}
.estimate .infos .col,
.estimate .infos .row {
    padding: 0px;
    margin: 0px;
}
table {
    width: 100%;
    border: none;
}
table th {
    text-align: left;
    font-weight: normal;
    border: none;
    border-bottom: 3px solid #aaa;
}
.estimate table th {
    border-bottom: 1px solid #000;
}
table td {
    padding: 5px;
}
table .desc {
    width: 50%;
}
.end_price {
    text-align: right;
}
.line_top {
    border-top: 1px solid #ddd;
}
.estimate table .liner td {
    border-bottom: 1px solid #000 !important;
}
.product {
    height: 45px;
}
.product.with_desc {
    height: 50px;
}
.footer {
    position: absolute;
    bottom: 0px;
    padding: 20px 0px 50px 0px;
    border-top: 0.5px solid #ddd;
    width: calc(100% - 120px);
}
.estimate .footer {
    padding: 20px 0px 50px 0px;
    font-size: 12px;
}
.payment_infos {
    position: absolute;
    bottom: 105px;
}
.estimate .payment_infos {
    position: absolute;
    bottom: 125px;
}
.payment_conditions {
    font-size: 13px !important;
    bottom: 155px !important;
}
.sign .infos {
    font-size: 10px !important;
}
.full_width {
    width: calc(100% - 120px);
}
.center {
    text-align: center;
}
</style>