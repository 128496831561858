<template>
    <v-app>
        <v-row :class='[track_data.printing ? "printing":"fill-height"]'>
            <v-col
                cols="4"
                v-if="!track_data.printing"
                class='fill-height'
            >
                <!--
                    Left side Editor
                    @binding {object} invoice_data shared through the app
                -->
                <editor
                    :invoice_data="invoice_data"
                    :base='base_invoice'
                    :track_data="track_data"
                ></editor>
            </v-col>
            <v-col cols="8">
                <!--
                    Right Main side invoice viewer
                    @binding {object} invoice_data shared through the app
                    @binding {track_data} track_data shared meta data through the app
                -->
                <invoice
                    :invoice_data="invoice_data"
                    :track_data="track_data"
                ></invoice>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
import Editor from './components/editor.vue'
import invoice from './components/invoice.vue'

/**
 * Main Application entry
 * @DisplayName Invoicer App
 * 
 */
export default {
    components: { invoice, Editor },
    name: 'App',
    data: () => ({
        track_data: {
            printing: false,
            proposers: JSON.parse(localStorage.getItem('proposers') ?? '{}'),
        },
        base_invoice: null,
        invoice_data: {
            version: '0.21.0',
            custom: {
                before_string: '',
                after_string: '',
                logo: 'company_logo.png',
                logo_scale: 1,
                more_lines: '',
            },
            mode: 'invoice',
            number: 'INVOICE-54e78f4ze65f4',
            emit_day: '7 janvier 2022',
            payday: '20 février 2022',
            expire_day: '18 février 2022',
            currency: '€',
            paied: false,
            give_back: 0,
            company: {
                name: 'My COmpany Name',
                street: 'Street Adress',
                city: '69003 Lyon',
                country: 'France',
                telephone: '+33 0 00 00 00',
                email: 'contact@mycompany.com',
                siren: '000 000 000',
            },
            client: {
                name: 'Client',
                street: 'Client Street Adress',
                city: '69003 Lyon',
                country: 'France',
                telephone: '+33 0 00 00 00',
                email: 'contact@client.com',
                siren: '000 000 000',
            },
            products: [
                {
                    name: 'product A',
                    description: 'product A is good',
                    price: 150.2,
                    quantity: 2,
                    pay_type: 'one-time',
                    period: '',
                },
                {
                    name: 'Other Product',
                    description: 'Very good product too',
                    price: 320,
                    quantity: 3,
                    pay_type: 'recurring',
                    period: 'month',
                }
            ],
            payment_method: [{
                name: 'virement banque 1',
                mode: 'Virement bancaire',
                infos: `Data name: data\nother: More Data`
            }],
            payment_conditions: {
                before: '50%',
                during: '10%',
                after: '40%'
            }
        }
    }),
    watch: {
        invoice_data: {
            handler() {
                this.invoice_data.version = this.base_invoice.version
                localStorage.setItem('invoice_data', JSON.stringify(this.invoice_data))
            },
            deep: true
        },
        'track_data.proposers': {
            handler() {
                localStorage.setItem('proposers', JSON.stringify(this.track_data.proposers))
            },
            deep: true
        }
    },
    methods: {
        date_str(date) {
            const months = [
                'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
                'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'
            ]
            const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()]
            return `${day} ${months[month]} ${year}`
        }
    },
    mounted() {
        this.$set(this, 'base_invoice', JSON.parse(JSON.stringify(this.invoice_data)))
        const saved_invoice_data = JSON.parse(localStorage.getItem('invoice_data') ?? null)
        if (saved_invoice_data && saved_invoice_data.version == this.invoice_data.version) this.$set(this, 'invoice_data', saved_invoice_data)
        // this.invoice_data.emit_day = this.date_str(new Date())

        window.set_invoice_data = (invoice) => {
            this.$set(this, 'invoice_data', invoice)
            this.$set(this.track_data, 'printing', true)
        }

        window.copy_invoice_data = () => {
            setTimeout(() => navigator.clipboard.writeText(JSON.stringify(this.invoice_data)), 1000)
            console.log(JSON.stringify(this.invoice_data))
        }

        window.set_invoice = (invoice) => {
            this.$set(this, 'invoice_data', invoice)
        }

        window.copy_local_base = () => {
            setTimeout(() => navigator.clipboard.writeText(JSON.stringify(this.track_data.proposers)), 1000)
            console.log(JSON.stringify(this.track_data.proposers))
        }

        window.set_local_base = (base) => {
            this.$set(this.track_data, 'proposers', base)
        }
    }
};
</script>

<style>
html,
body,
.v-application {
    overflow: hidden !important;
    height: 100%;
}
.printing {
    /* height: 100px !important; */
}
.fill-height {
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #eee;
    /* border-radius: 4px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}
</style>
