<template>
    <div class='pa-5 editor'>
        <displayer
            :root="comp"
            :prop="'invoice'"
            :path="'invoice'"
            :base="base"
            :track_data="track_data"
        ></displayer>
    </div>
</template>

<script>
import displayer from "./displayer.vue"

export default {
    components: { displayer },

    props: ['invoice_data', 'track_data', 'base'],
    computed: {
        comp() {
            return { invoice: this.invoice_data }
        }
    }
}
</script>

<style>
.editor {
    height: 100%;
    overflow-y: scroll;
}
</style>