<template>
    <div class='pa-2 ml-5'>
        <!-- ---------------------------------------------------------- SELECT -->
        <template v-if='prop in specials && display'>
            <v-select
                v-if="Array.isArray(specials[prop])"
                :items="specials[prop]"
                v-model="root[prop]"
                :label="prop"
            ></v-select>
            <v-checkbox
                style='margin-left:-5px'
                v-if="typeof specials[prop] == 'boolean'"
                :label="prop"
                v-model="root[prop]"
            >
            </v-checkbox>
        </template>
        <!-- ---------------------------------------------------------- ARRAY -->
        <template v-else-if="Array.isArray(root[prop]) && display">
            <v-btn
                icon
                @click="show = !show"
                style='margin-left:-15px'
            >
                <v-icon>mdi-menu-{{show ? 'up' : 'down'}}</v-icon>
            </v-btn>
            <v-btn
                class='obj_name'
                v-if="!hide_name"
                text
                @click="show = !show"
            >ARRAY: {{prop}} ({{element.length}})</v-btn>
            <v-btn
                icon
                color='primary'
                class='
                ma-3'
                :disabled="this_array_max != null && element.length >= this_array_max"
                @click="add(prop)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <template v-if="show">
                <div
                    v-for="(data,index) in root[prop]"
                    :key="index"
                >
                    <v-divider></v-divider>
                    <displayer
                        :root="root[prop]"
                        :prop="index"
                        :hide_name="true"
                        :deletable="true"
                        :path="path+'.'+index"
                        :track_data="track_data"
                        :array_item="true"
                        :index="index"
                        @delete="root[prop].splice(index,1)"
                    ></displayer>
                    <v-divider></v-divider>
                </div>
            </template>
        </template>
        <!-- ---------------------------------------------------------- OBJECT -->
        <template v-else-if="typeof(root[prop]) == 'object' && display">
            <v-btn
                icon
                @click="show = !show"
                style='margin-left:-15px'
            >
                <v-icon>mdi-menu-{{show ? 'up' : 'down'}}</v-icon>
            </v-btn>
            <v-btn
                class='obj_name'
                v-if="!hide_name"
                text
                @click="show = !show"
            >{{prop}}</v-btn>
            <template v-if="show">
                <v-btn
                    v-if="deletable"
                    icon
                    color='primary'
                    @click="$emit('delete')"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <template v-if="proposer">
                    <v-btn
                        icon
                        :color="in_proposer ? '' : 'primary'"
                        @click="save_proposed"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="proposer.proposed.length > 0"
                        class='ml-3'
                        color="primary"
                        small
                        @click="display_proposed=!display_proposed"
                    >
                        <v-icon
                            left
                            v-if="display_proposed"
                        >mdi-close</v-icon>
                        {{proposer.proposer_name.replace(/_/g,' ')}}
                    </v-btn>
                    <div v-if="display_proposed">
                        <div
                            v-for="(item,index) in proposer.proposed"
                            :key="index"
                        >
                            <v-btn
                                class='obj_name'
                                text
                                @click="display_proposed=false;fill_with(item)"
                            >
                                - {{item[proposer.propose_disp_prop]}}
                            </v-btn>
                            <v-btn
                                icon
                                @click="proposer.proposed.splice(index,1)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </template>
                <template v-id="array_item">
                    <v-btn
                        icon
                        class='ml-3'
                        color='primary'
                        :disabled="index == 0"
                        @click="move_up"
                    >
                        <v-icon>mdi-menu-up</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color='primary'
                        :disabled="index == root.length-1"
                        @click="move_down"
                    >
                        <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-btn
                    v-if="base"
                    icon
                    class='ml-10'
                    small
                    color='primary'
                    @click="rebase"
                >
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
                <displayer
                    :root="root[prop]"
                    v-for="(v,sub_prop) in root[prop]"
                    :prop="sub_prop"
                    :key="sub_prop"
                    :path="path+'.'+sub_prop"
                    :track_data="track_data"
                ></displayer>
            </template>
        </template>
        <v-text-field
            v-else-if="display && !is_multi_line"
            v-model="root[prop]"
            :label="prop"
            :disabled="locked.includes(prop)"
        >
        </v-text-field>
        <v-textarea
            v-else-if="display && is_multi_line"
            v-model="root[prop]"
            :label="prop"
            :disabled="locked.includes(prop)"
        >
        </v-textarea>
    </div>
</template>

<script>
export default {
    name: 'displayer',
    props: ['root', 'prop', 'path', 'hide_name', 'deletable', 'array_item', 'index', 'track_data', 'base'],
    data: () => ({
        show: true,
        display_proposed: false,
        specials: {
            'currency': ['€', '$', '£'],
            'pay_type': ['one-time', 'recurring'],
            'period': ['', 'day', 'week', 'month', 'year'],
            'mode': ['invoice', 'estimate'],
            'paied': false,
        },
        un_display: {
            period: ['pay_type', 'one-time'],
            payment_method: ['mode', 'estimate'],
            payment_conditions: ['mode', 'invoice'],
            expire_day: ['mode', 'invoice'],
            paied: ['mode', 'estimate'],
        },
        locked: ['version'],
        adders: {
            products: {
                name: 'name',
                description: 'description',
                price: 10,
                quantity: 1,
                pay_type: 'one-time',
                period: ''
            },
            payment_method: {
                name: 'name for storage',
                mode: 'mode',
                infos: 'Data name: data\nother: More Data'
            }
        },
        multi_line_paths: [
            'invoice.payment_method.0.infos',
            'invoice.payment_conditions',
            'invoice.custom.more_lines'
        ],
        array_max: {
            'invoice.payment_method': 1
        },
        path_proposers: {
            'invoice': 'invoices',
            'invoice.company': 'companies',
            'invoice.client': 'clients',
            'invoice.products.#': 'products',
            'invoice.payment_method.0': 'payment_methods',
        },
        proposers_disp_prop: {
            'invoices': 'number',
            'companies': 'name',
            'clients': 'name',
            'products': 'name',
            'payment_methods': 'name',
        },
    }),
    watch: {
        show() {
            localStorage.setItem(this.path + '_show', JSON.stringify(this.show))
        }
    },
    computed: {
        proposers() {
            return this.track_data.proposers
        },
        element() {
            return this.root[this.prop]
        },
        display() {
            const my_undisplay = this.un_display[this.prop]
            if (!my_undisplay) return true
            const [from_prop, value] = my_undisplay
            return !(this.root[from_prop] === value)
        },
        this_array_max() {
            return Object.entries(this.array_max).find(([p]) => this.check_path(this.path, p))?.[1]
        },
        is_multi_line() {
            return !!this.multi_line_paths.find(p => this.check_path(this.path, p))
        },
        proposer() {
            const elms = this.path.split('.')
            if (!elms.length) return null
            const proposer_path = Object.keys(this.path_proposers).find(prop_path => {
                const prop_elms = prop_path.split('.')
                if (prop_elms.length != elms.length) return false
                const is_path = prop_elms.map((elm, i) => elm == '#' || elms[i] == elm).reduce((a, b) => a && b, true)
                return is_path
            })
            if (!proposer_path) return null
            const proposer_name = this.path_proposers[proposer_path]
            if (!this.proposers[proposer_name]) this.$set(this.proposers, proposer_name, [])
            const proposed = this.proposers[proposer_name]
            const propose_disp_prop = this.proposers_disp_prop[proposer_name]
            return { proposer_name, proposed, propose_disp_prop }
        },
        in_proposer_index() {
            const prop = this.proposer
            if (!prop) return false
            const being_proposed = this.element
            const proposed = this.proposers[prop.proposer_name]
            const proposed_filters = proposed.map(p => p[prop.propose_disp_prop])
            const this_filter = being_proposed[prop.propose_disp_prop]
            return proposed_filters.indexOf(this_filter)
        },
        in_proposer() {
            return this.in_proposer_index > -1
        }
    },
    methods: {
        check_path(path1, path2) {
            const elms = path1.split('.')
            if (!elms.length) return null
            const prop_elms = path2.split('.')
            if (prop_elms.length != elms.length) return false
            const is_path = prop_elms.map((elm, i) => elm == '#' || elms[i] == elm).reduce((a, b) => a && b, true)
            return is_path
        },
        save_proposed() {
            const being_proposed = JSON.parse(JSON.stringify(this.element))
            const prop = this.proposer
            const proposed = this.proposers[prop.proposer_name]
            if (this.in_proposer) this.$set(proposed, this.in_proposer_index, being_proposed)
            else proposed.push(being_proposed)
        },
        fill_with(obj, destroy = false) {
            const filler = (from, to) => {
                if (!from) return
                if (destroy && !Array.isArray(to)) {
                    const from_props = Object.keys(from)
                    const to_props = Object.keys(to)
                    const del_props = to_props.filter(tp => !from_props.includes(tp))
                    del_props.forEach(p => this.$delete(to, p))
                }
                for (const prop in from) {
                    if (to[prop] === undefined) continue
                    if (Array.isArray(to[prop])) {
                        this.$set(to, prop, Array(from[prop].length).fill(0))
                        filler(from[prop], to[prop])
                    }
                    else if (typeof to[prop] == 'object') {
                        filler(from[prop], to[prop])
                    } else {
                        to[prop] = from[prop]
                    }
                }
            }
            filler(obj, this.element)
        },
        add(prop) {
            if (this.adders[prop]) {
                this.element.push(JSON.parse(JSON.stringify(this.adders[this.prop])))
            }
        },
        remove(index) {
            this.element.splice(index, 1)
        },
        rebase() {
            this.fill_with(JSON.parse(JSON.stringify(this.base), this.element, true))
        },
        move_up() {
            const tmp = this.root[this.index]
            this.$set(this.root, this.index, this.root[this.index - 1])
            this.$set(this.root, this.index - 1, tmp)
        },
        move_down() {
            const tmp = this.root[this.index]
            this.$set(this.root, this.index, this.root[this.index + 1])
            this.$set(this.root, this.index + 1, tmp)
        }
    },
    mounted() {
        const show = JSON.parse(localStorage.getItem(this.path + '_show') ?? 'true')
        this.show = show
    }
}
</script>

<style>
.obj_name {
    text-transform: none;
    font-size: inherit;
}
</style>